import React from 'react'

const ListIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 458.18 458.18"
      className="texticon"
    >
      <path d="M36.09 5.948c-18.803 0-34.052 15.248-34.052 34.051 0 18.803 15.249 34.052 34.052 34.052 18.803 0 34.052-15.25 34.052-34.052 0-18.803-15.249-34.051-34.052-34.051zM147.537 80h268.604c22.092 0 40-17.908 40-40s-17.908-40-40-40H147.537c-22.092 0-40 17.908-40 40s17.908 40 40 40zM36.09 132.008c-18.803 0-34.052 15.248-34.052 34.051s15.249 34.052 34.052 34.052c18.803 0 34.052-15.249 34.052-34.052s-15.249-34.051-34.052-34.051zM416.142 126.06H147.537c-22.092 0-40 17.908-40 40s17.908 40 40 40h268.604c22.092 0 40-17.908 40-40s-17.908-40-39.999-40zM36.09 258.068c-18.803 0-34.052 15.248-34.052 34.051 0 18.803 15.249 34.052 34.052 34.052 18.803 0 34.052-15.249 34.052-34.052 0-18.803-15.249-34.051-34.052-34.051zM416.142 252.119H147.537c-22.092 0-40 17.908-40 40s17.908 40 40 40h268.604c22.092 0 40-17.908 40-40s-17.908-40-39.999-40zM36.09 384.128c-18.803 0-34.052 15.248-34.052 34.051s15.249 34.053 34.052 34.053c18.803 0 34.052-15.25 34.052-34.053s-15.249-34.051-34.052-34.051zM416.142 378.18H147.537c-22.092 0-40 17.908-40 40s17.908 40 40 40h268.604c22.092 0 40-17.908 40-40s-17.908-40-39.999-40z" />
    </svg>
  )
}

export default ListIcon
